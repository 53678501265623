/* ===== Scrollbar CSS ===== */
      /* Firefox */
      * {
        scrollbar-width: auto;
        scrollbar-color: #3D3D3D #3D3D3D;
      }

      /* Chrome, Edge, and Safari */
      *::-webkit-scrollbar {
        width: 4px;
        transition: 0.3s;
        display: block;
      }
      *::-webkit-scrollbar:hover {
        width: 8px;
        transition: 0.3s;
      }

      *::-webkit-scrollbar-track {
        background: transparent;
        position: absolute;
        right: -3rem;
        top: -50rem;
      }
      
      *::-webkit-scrollbar-track-piece:start {
        background: transparent;
      }
  
      *::-webkit-scrollbar-track-piece:end {
        background: transparent;
      }

      *::-webkit-scrollbar-thumb {
        background-color: #3D3D3D;
        border-radius: 8px;
      }
      *::-webkit-scrollbar-thumb:hover {
        background-color: #3D3D3D;
        border-radius: 8px;
      }
      
      html {
        scroll-behavior: smooth;
    }
    body {
      overflow-x: hidden;
      position: relative;
    }
    a {
      text-decoration: none;
    }
    .prevent-scrolling {
      overflow-y: hidden;
    }

    .Mui-selected {
      color: white !important;
      background-color: #6F5135 !important;
    }
    .MuiBox-root .image{
      margin: 0;
    }
    .MuiBox-root .image img{
      width: 100%;
    }
    .MuiBox-root .image figcaption em {
        background-color: transparent !important;
        color: #dedede !important;
        font-size: 0.9em !important;
        font-family: Inter,-apple-system,BlinkMacSystemFont,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    }